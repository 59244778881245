// src/pages/SettingsPage/SettingsPage.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { authService } from '../../services/auth.service';
import Sidebar from '../../components/Dashboard/Sidebar';
import './SettingsPage.css';

const SettingsPage = () => {
    const navigate = useNavigate();
    const [notification, setNotification] = useState(null);
    const [loading, setLoading] = useState(true);

    const [userData, setUserData] = useState({
        username: '',
        email: '',
        created_at: ''
    });

    const [passwordData, setPasswordData] = useState({
        currentPassword: '',
        newPassword: ''
    });

    useEffect(() => {
        loadUserData();
    }, []);

    const loadUserData = async () => {
        try {
            const data = await authService.getCurrentUser();
            setUserData(data);
        } catch (error) {
            showNotification('error', 'Erreur lors du chargement des données');
        } finally {
            setLoading(false);
        }
    };

    const showNotification = (type, message) => {
        setNotification({ type, message });
        setTimeout(() => setNotification(null), 3000);
    };

    const handlePasswordChange = async (e) => {
        e.preventDefault();
        if (!passwordData.currentPassword || !passwordData.newPassword) {
            showNotification('error', 'Veuillez remplir tous les champs');
            return;
        }

        try {
            await authService.changePassword(passwordData);
            showNotification('success', 'Mot de passe modifié avec succès');
            setPasswordData({
                currentPassword: '',
                newPassword: ''
            });
        } catch (error) {
            showNotification('error', 'Erreur lors du changement de mot de passe');
        }
    };

    const handleLogout = () => {
        authService.logout();
        navigate('/login');
    };

    if (loading) {
        return (
            <div className="app-container">
                <Sidebar />
                <main className="main-content">
                    <div className="loading-spinner"></div>
                </main>
            </div>
        );
    }

    return (
        <div className="app-container">
            <Sidebar />
            <main className="main-content">
                <header className="content-header">
                    <h1 className="content-title">Paramètres</h1>
                </header>

                <div className="settings-container">

                    <section className="settings-section user-info-section">
                        <div className="section-content">
                            <div className="info-row">
                                <span className="info-label">Nom d'utilisateur</span>
                                <span className="info-value">{userData.username}</span>
                            </div>
                            <div className="info-row">
                                <span className="info-label">Email</span>
                                <span className="info-value">{userData.email}</span>
                            </div>
                            <div className="info-row">
                                <span className="info-label">Membre depuis</span>
                                <span className="info-value">
                                    {new Date(userData.created_at).toLocaleDateString('fr-FR', {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric'
                                    })}
                                </span>
                            </div>
                        </div>
                    </section>

                    <section className="settings-section">
                        <h2>Changer le mot de passe</h2>
                        {notification && (
                            <div className={`notification-setting ${notification.type}`}>
                                <i className={`fas ${notification.type === 'success' ? 'fa-check-circle' : 'fa-exclamation-circle'}`}></i>
                                {notification.message}
                            </div>
                        )}
                        <form onSubmit={handlePasswordChange}>
                            <div className="password-fields">
                                <div className="form-group">
                                    <label htmlFor="currentPassword">Mot de passe actuel</label>
                                    <input
                                        type="password"
                                        id="currentPassword"
                                        value={passwordData.currentPassword}
                                        onChange={(e) => setPasswordData({ ...passwordData, currentPassword: e.target.value })}
                                        className="settings-input"
                                        placeholder="Entrez votre mot de passe actuel"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="newPassword">Nouveau mot de passe</label>
                                    <input
                                        type="password"
                                        id="newPassword"
                                        value={passwordData.newPassword}
                                        onChange={(e) => setPasswordData({ ...passwordData, newPassword: e.target.value })}
                                        className="settings-input"
                                        placeholder="Entrez votre nouveau mot de passe"
                                    />
                                </div>
                            </div>
                            <button type="submit" className="action-btn btn-primary">
                                <i className="fas fa-key"></i>
                                Mettre à jour le mot de passe
                            </button>
                        </form>
                    </section>

                    <section className="settings-section">
                        <h2>Session</h2>
                        <button onClick={handleLogout} className="action-btn btn-danger">
                            <i className="fas fa-sign-out-alt"></i>
                            Déconnexion
                        </button>
                    </section>
                </div>
            </main>
        </div>
    );
};

export default SettingsPage;