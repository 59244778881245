// src/pages/LandingPage/LandingPage.jsx
import React from 'react';
import Navbar from '../../components/Navbar/Navbar.jsx';
import Hero from '../../components/Hero/Hero.jsx';
import Features from '../../components/Features/Features.jsx';
import Stats from '../../components/Stats/Stats.jsx';
import Testimonials from '../../components/Testimonials/Testimoniales.jsx';
import CTA from '../../components/CTA/CTA.jsx';
import Footer from '../../components/Footer/Footer.jsx';
import '../../styles/global.css';

const LandingPage = () => {
    return (
        <div className="landing-page">
            <div className="glass-bg">
                <div className="glass-blob blob-1"></div>
                <div className="glass-blob blob-2"></div>
            </div>
            <Navbar />
            <Hero />
            <Features />
            <Stats />
            <CTA />
            <Footer />
        </div>
    );
};

export default LandingPage;