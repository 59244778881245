import React, { useState, useCallback } from 'react';
import { Layout, Model, Actions, DragActionsLayout } from 'flexlayout-react';
import { Box, ThemeProvider, createTheme } from '@mui/material';
import 'flexlayout-react/style/dark.css';
import '../../styles/custom-flexlayout-styles.css';

const FlexibleLayout = ({ components }) => {
    const [model, setModel] = useState(() => {
        const initialModel = {
            global: {},
            layout: {
                type: 'row',
                weight: 100,
                children: [
                    {
                        type: 'tabset',
                        weight: 30,
                        children: [
                            {
                                type: 'tab',
                                name: 'Transcript',
                                component: 'transcriptViewer',
                            },
                        ],
                    },
                    {
                        type: 'row',
                        weight: 70,
                        children: [
                            {
                                type: 'tabset',
                                weight: 70,
                                children: [
                                    {
                                        type: 'tab',
                                        name: 'Video Player',
                                        component: 'videoPlayer',
                                    },
                                ],
                            },
                            {
                                type: 'tabset',
                                weight: 50,
                                children: [
                                    {
                                        type: 'tab',
                                        name: 'Timeline',
                                        component: 'timeline',
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        };
        return Model.fromJson(initialModel);
    });

    const factory = (node) => {
        const component = node.getComponent();
        return components[component];
    };

    const onModelChange = useCallback((newModel) => {
        setModel(newModel);
    }, []);

    return (
        <Box sx={{ height: '100%', overflow: 'hidden' }}>
            <Layout
                model={model}
                factory={factory}
                onModelChange={onModelChange}
                actions={Actions}
                dragActions={DragActionsLayout}
            />
        </Box>
    );
};

export default FlexibleLayout;