import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, IconButton, CircularProgress } from '@mui/material';
import { PlayArrow, Pause, FastForward, FastRewind } from '@mui/icons-material';

const VideoPlayer = ({ videoRef, currentTime, duration, isPlaying, handlePlayPause, handleSkip, videoUrl }) => {
    const containerRef = useRef(null);
    const [containerSize, setContainerSize] = useState({ width: '100%', height: '100%' });
    const [controlsHeight, setControlsHeight] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const updateSize = () => {
            if (containerRef.current && (videoRef.current || !videoUrl)) {
                const containerWidth = containerRef.current.clientWidth;
                const containerHeight = containerRef.current.clientHeight;
                let videoAspectRatio = 16 / 9; // Default aspect ratio when no video

                if (videoRef.current) {
                    videoAspectRatio = videoRef.current.videoWidth / videoRef.current.videoHeight;
                }

                let newWidth = containerWidth;
                let newHeight = containerHeight - controlsHeight;

                if (newHeight > newWidth / videoAspectRatio) {
                    newHeight = newWidth / videoAspectRatio;
                } else {
                    newWidth = newHeight * videoAspectRatio;
                }

                setContainerSize({ width: `${newWidth}px`, height: `${newHeight}px` });
            }
        };

        const resizeObserver = new ResizeObserver(updateSize);
        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        return () => resizeObserver.disconnect();
    }, [videoRef, controlsHeight, videoUrl]);

    useEffect(() => {
        if (containerRef.current) {
            const controls = containerRef.current.querySelector('.video-controls');
            if (controls) {
                setControlsHeight(controls.offsetHeight);
            }
        }
    }, []);

    useEffect(() => {
        if (videoRef.current) {
            // videoRef.current.currentTime = currentTime;
        }
    }, [currentTime]);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.addEventListener('canplaythrough', () => {
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }, [videoRef]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    return (
        <Box ref={containerRef} sx={{
            width: '100%',
            height: '100%',
            bgcolor: '#2D2D2D',
            borderRadius: 1,
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Box sx={{ width: containerSize.width, height: containerSize.height, overflow: 'hidden', position: 'relative' }}>
                {videoUrl ? (
                    <video
                        ref={videoRef}
                        src={videoUrl}
                        muted={true}
                        preload='auto'
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                ) : (
                    <Box sx={{
                        width: '100%',
                        height: '100%',
                        bgcolor: '#000000',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Typography variant="body1" sx={{ color: 'rgba(255,255,255,0.7)' }}>
                            No video available
                        </Typography>
                    </Box>
                )}
            </Box>
            <Box className="video-controls" sx={{
                width: '98%',
                bgcolor: '#2D2D2D',
                p: 1.5,
            }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.7)' }}>
                        {formatTime(currentTime)}
                    </Typography>

                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton
                            onClick={() => handleSkip(-10)}
                            sx={{ color: 'white', p: 0.5 }}
                        >
                            <FastRewind fontSize="small" />
                        </IconButton>
                        <IconButton
                            onClick={handlePlayPause}
                            sx={{ color: 'white', mx: 1, p: 0.5 }}
                        >
                            {isPlaying ? <Pause fontSize="small" /> : <PlayArrow fontSize="small" />}
                        </IconButton>
                        <IconButton
                            onClick={() => handleSkip(10)}
                            sx={{ color: 'white', p: 0.5 }}
                        >
                            <FastForward fontSize="small" />
                        </IconButton>
                    </Box>

                    <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.7)' }}>
                        {formatTime(duration)}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default VideoPlayer;