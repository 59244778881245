import React, { useState, useEffect } from 'react';
import './FiltersModal.css';

const FiltersModal = ({ isOpen, onClose, filters, onFiltersChange, languages }) => {
    // État local pour les filtres temporaires
    const [tempFilters, setTempFilters] = useState({
        statuses: [],
        languages: []
    });

    // Initialiser les filtres temporaires quand le modal s'ouvre
    useEffect(() => {
        if (isOpen) {
            setTempFilters({
                statuses: filters.statuses || [],
                languages: filters.languages || []
            });
        }
    }, [isOpen, filters]);

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [isOpen]);

    const handleStatusToggle = (status) => {
        const newStatuses = tempFilters.statuses.includes(status)
            ? tempFilters.statuses.filter(s => s !== status)
            : [...tempFilters.statuses, status];

        setTempFilters(prev => ({
            ...prev,
            statuses: newStatuses
        }));
    };

    const handleLanguageToggle = (language) => {
        const newLanguages = tempFilters.languages.includes(language)
            ? tempFilters.languages.filter(l => l !== language)
            : [...tempFilters.languages, language];

        setTempFilters(prev => ({
            ...prev,
            languages: newLanguages
        }));
    };

    const clearFilters = () => {
        onFiltersChange({
            statuses: [],
            languages: []
        });
    };

    const handleApply = () => {
        onFiltersChange({
            ...filters,
            ...tempFilters
        });
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="filters-modal-backdrop" onClick={onClose}>
            <div className="filters-modal" onClick={e => e.stopPropagation()}>
                <div className="filters-modal__header">
                    <h2 className="filters-modal__title">
                        <i className="fas fa-filter"></i>
                        Filtrer les projets
                    </h2>
                    <button className="filters-modal__close" onClick={onClose}>
                        <i className="fas fa-times"></i>
                    </button>
                </div>

                <div className="filters-modal__content">
                    <div className="filters-modal__section">
                        <h3 className="filters-modal__section-title">
                            <i className="fas fa-chart-line"></i>
                            Status
                        </h3>
                        <div className="filters-modal__options">
                            <button
                                className={`filters-modal__option ${tempFilters.statuses.includes('progress') ? 'active' : ''}`}
                                onClick={() => handleStatusToggle('progress')}
                            >
                                <i className="fas fa-clock"></i>
                                En traitement
                                <i className={`fas fa-check filters-modal__check ${tempFilters.statuses.includes('progress') ? 'visible' : ''}`}></i>
                            </button>
                            <button
                                className={`filters-modal__option ${tempFilters.statuses.includes('completed') ? 'active' : ''}`}
                                onClick={() => handleStatusToggle('completed')}
                            >
                                <i className="fas fa-check-circle"></i>
                                Terminé
                                <i className={`fas fa-check filters-modal__check ${tempFilters.statuses.includes('completed') ? 'visible' : ''}`}></i>
                            </button>
                            <button
                                className={`filters-modal__option ${tempFilters.statuses.includes('failed') ? 'active' : ''}`}
                                onClick={() => handleStatusToggle('failed')}
                            >
                                <i className="fas fa-times-circle"></i>
                                Échoué
                                <i className={`fas fa-check filters-modal__check ${tempFilters.statuses.includes('failed') ? 'visible' : ''}`}></i>
                            </button>
                        </div>
                    </div>

                    <div className="filters-modal__section">
                        <h3 className="filters-modal__section-title">
                            <i className="fas fa-language"></i>
                            Langues
                        </h3>
                        <div className="filters-modal__options filters-modal__options--grid">
                            {languages.map(lang => (
                                <button
                                    key={lang.code}
                                    className={`filters-modal__option ${tempFilters.languages.includes(lang.code) ? 'active' : ''}`}
                                    onClick={() => handleLanguageToggle(lang.code)}
                                >
                                    {lang.name}
                                    <i className={`fas fa-check filters-modal__check ${tempFilters.languages.includes(lang.code) ? 'visible' : ''}`}></i>
                                </button>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="filters-modal__footer">
                    <button
                        className="filters-modal__clear"
                        onClick={clearFilters}
                        disabled={tempFilters.statuses.length === 0 && tempFilters.languages.length === 0}
                    >
                        <i className="fas fa-trash-alt"></i>
                        Effacer les filtres
                    </button>
                    <button
                        className="filters-modal__apply"
                        onClick={handleApply}
                    >
                        Appliquer
                        {(tempFilters.statuses.length > 0 || tempFilters.languages.length > 0) && (
                            <span className="filters-modal__count">
                                {tempFilters.statuses.length + tempFilters.languages.length}
                            </span>
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FiltersModal;