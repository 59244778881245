import React from 'react';
import { Link } from 'react-router-dom';
import './CTA.css';

const CTA = () => {
    const handleClick = () => {
        // Faire défiler la page vers le haut
        window.scrollTo({
            top: 0,
        });
    };

    return (
        <section className="cta-section">
            <div className="cta-content">
                <h2>Prêt à transformer votre contenu ?</h2>
                <p>Rejoignez des milliers de créateurs qui utilisent déjà Sumfy pour toucher une audience internationale</p>
                <div className="cta-buttons">
                    <Link
                        to="/demo"
                        className="cta-button cta-white"
                        onClick={handleClick}
                    >
                        Essayer maintenant
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default CTA;