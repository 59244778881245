import React, { useCallback, useState, useEffect } from 'react';
import Sidebar from '../../components/Dashboard/Sidebar';
import ProjectGrid from '../../components/Dashboard/ProjectGrid';
import NewProjectModal from '../../components/Dashboard/NewProjectModal';
import FiltersModal from '../../components/Dashboard/FiltersModal';
import { projectService } from '../../services/project.service.js';
import './DashboardPage.css';
import '../../components/Dashboard/FiltersModal.css';

const DashboardPage = () => {
    // États pour les modaux
    const [isProjectModalOpen, setIsProjectModalOpen] = useState(false);
    const [isFiltersOpen, setIsFiltersOpen] = useState(false);

    // État pour le rafraîchissement des projets
    const [shouldRefreshProjects, setShouldRefreshProjects] = useState(false);

    // États pour les données
    const [languages, setLanguages] = useState([]);
    const [filters, setFilters] = useState({
        search: '',
        statuses: [], // ['progress', 'completed', 'failed']
        languages: [] // ['fr', 'en', etc.]
    });

    // Gestionnaire de recherche
    const handleSearchChange = useCallback((e) => {
        setFilters(prev => ({
            ...prev,
            search: e.target.value
        }));
    }, []);

    // Gestionnaire pour la création de projet
    const handleProjectCreated = useCallback(() => {
        setShouldRefreshProjects(true);
    }, []);

    // Gestionnaire pour la fin du rafraîchissement
    const handleRefreshComplete = useCallback(() => {
        setShouldRefreshProjects(false);
    }, []);

    // Chargement initial des langues disponibles
    useEffect(() => {
        const fetchLanguages = async () => {
            try {
                const languagesData = await projectService.getLanguages();
                setLanguages(languagesData);
            } catch (error) {
                console.error('Erreur lors du chargement des langages:', error);
                // Option: Ajouter un state pour gérer l'erreur de chargement des langues
            }
        };

        fetchLanguages();
    }, []);

    // Calcul du nombre de filtres actifs
    const activeFiltersCount = filters.statuses.length + filters.languages.length;

    return (
        <div className="app-container">
            {/* Sidebar */}
            <Sidebar />

            {/* Contenu principal */}
            <main className="main-content">
                {/* En-tête avec filtres */}
                <header className="content-header">
                    <h1 className="content-title">Mes Projets</h1>

                    <div className="header-actions">
                        {/* Barre de recherche */}
                        <div className="filter-group">
                            <i className="fas fa-search"></i>
                            <input
                                type="text"
                                className="search-input"
                                placeholder="Rechercher un projet..."
                                value={filters.search}
                                onChange={handleSearchChange}
                            />
                        </div>

                        {/* Bouton des filtres avec badge */}
                        <div className="filters-dropdown">
                            <button
                                className="action-btn btn-secondary"
                                onClick={() => setIsFiltersOpen(true)}
                            >
                                <i className="fas fa-filter"></i>
                                Filtres
                                {activeFiltersCount > 0 && (
                                    <span className="filter-badge">
                                        {activeFiltersCount}
                                    </span>
                                )}
                            </button>

                            {/* Modal des filtres */}
                            <FiltersModal
                                isOpen={isFiltersOpen}
                                onClose={() => setIsFiltersOpen(false)}
                                filters={filters}
                                onFiltersChange={setFilters}
                                languages={languages}
                            />
                        </div>
                    </div>
                </header>

                {/* Grille des projets */}
                <ProjectGrid
                    shouldRefresh={shouldRefreshProjects}
                    onRefreshComplete={handleRefreshComplete}
                    filters={filters}
                />
            </main>

            {/* Bouton flottant d'ajout de projet */}
            <button
                className="floating-action-btn"
                onClick={() => setIsProjectModalOpen(true)}
                title="Nouveau projet"
            >
                <i className="fas fa-plus"></i>
            </button>

            {/* Modal de création de projet */}
            <NewProjectModal
                isOpen={isProjectModalOpen}
                onClose={() => setIsProjectModalOpen(false)}
                onProjectCreated={handleProjectCreated}
                allLanguages={languages}
            />
        </div>
    );
};

export default DashboardPage;