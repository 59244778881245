import React from 'react';
import './LegalNotice.css';

const LegalNotice = () => {
    return (
        <div className="legal-container">
            <div className="legal-content">
                <h1>Mentions Légales</h1>
                <p className="last-updated">Dernière mise à jour : 2 Décembre 2024</p>

                <section className="legal-section">
                    <h2>1. Propriété intellectuelle</h2>
                    <p>
                        L'ensemble du site Sumfy.com, y compris sa structure, son design, ses textes,
                        ses images, ses logos, et la technologie de synthèse vocale, est protégé par
                        les lois sur la propriété intellectuelle.
                    </p>
                    <p>
                        Toute reproduction, représentation, modification, publication, transmission,
                        dénaturation, totale ou partielle du site ou de son contenu, par quelque
                        procédé que ce soit, et sur quelque support que ce soit est interdite sans
                        autorisation préalable écrite de Sumfy SAS.
                    </p>
                </section>

                <section className="legal-section">
                    <h2>2. Protection des données personnelles</h2>
                    <p>
                        Conformément au Règlement Général sur la Protection des Données (RGPD) et à
                        la Loi Informatique et Libertés, vous disposez d'un droit d'accès, de
                        rectification et de suppression des données vous concernant.
                    </p>
                    <p>
                        Délégué à la protection des données (DPO) :<br />
                        Email : contact.sumfy@gmail.com
                    </p>
                </section>

                <section className="legal-section">
                    <h2>3. Conditions d'utilisation</h2>
                    <p>
                        L'utilisation du site Sumfy.com implique l'acceptation pleine et entière des
                        conditions générales d'utilisation décrites dans nos CGU. Ces conditions
                        d'utilisation sont susceptibles d'être modifiées ou complétées à tout moment.
                    </p>
                </section>

                <section className="legal-section">
                    <h2>4. Limitation de responsabilité</h2>
                    <p>
                        Sumfy s'efforce d'assurer l'exactitude et la mise à jour des informations
                        diffusées sur son site. Toutefois, Sumfy ne peut garantir l'exactitude, la
                        précision ou l'exhaustivité des informations mises à disposition sur le site.
                    </p>
                </section>

                <section className="legal-section">
                    <h2>5. Loi applicable et juridiction</h2>
                    <p>
                        Les présentes mentions légales sont régies par la loi française. En cas de
                        litige, les tribunaux français seront seuls compétents.
                    </p>
                </section>
            </div>
        </div>
    );
};

export default LegalNotice;