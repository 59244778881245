// src/components/Stats/Stats.jsx
import React from 'react';
import './Stats.css';

const Stats = () => {
    const statsData = [
        {
            number: "29+",
            label: "Langues"
        },
        {
            number: "1M+",
            label: "Minutes doublées"
        },
        {
            number: "x5",
            label: "Portée globale"
        }
    ];

    return (
        <section className="stats">
            <div className="stats-grid">
                {statsData.map((stat, index) => (
                    <div key={index} className="stat-item">
                        <h2>{stat.number}</h2>
                        <p>{stat.label}</p>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Stats;