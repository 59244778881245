import React, { useState } from 'react';
import { projectService } from '../../services/project.service.js';
import './NewProjectModal.css';

const MAX_FILE_SIZE = 100 * 1024 * 1024; // 100MB
const ACCEPTED_FILE_TYPES = ['audio/', 'video/', '.wav'];
const MIN_PROJECT_NAME_LENGTH = 3;
const MAX_PROJECT_NAME_LENGTH = 50;

// Validation helper functions
const validateProjectName = (name) => {
    if (!name.trim()) {
        return 'Le nom du projet est requis';
    }
    if (name.length < MIN_PROJECT_NAME_LENGTH) {
        return `Le nom du projet doit contenir au moins ${MIN_PROJECT_NAME_LENGTH} caractères`;
    }
    if (name.length > MAX_PROJECT_NAME_LENGTH) {
        return `Le nom du projet ne peut pas dépasser ${MAX_PROJECT_NAME_LENGTH} caractères`;
    }
    if (/[<>:"\/\\|?*]/.test(name)) {
        return 'Le nom du projet contient des caractères non autorisés';
    }
    return null;
};

const validateFiles = (files) => {
    if (files.length === 0) {
        return 'Veuillez ajouter au moins un fichier';
    }

    for (const file of files) {
        if (file.size > MAX_FILE_SIZE) {
            return `Le fichier "${file.name}" dépasse la taille maximale autorisée (100 MB)`;
        }

        const isValidType = ACCEPTED_FILE_TYPES.some(type =>
            file.type.startsWith(type) || (type.startsWith('.') && file.name.endsWith(type))
        );

        if (!isValidType) {
            return `Le fichier "${file.name}" n'est pas dans un format audio/vidéo accepté`;
        }
    }

    return null;
};

const validateLanguages = (languages) => {
    if (languages.length === 0) {
        return 'Veuillez sélectionner au moins une langue';
    }
    return null;
};

const NewProjectModal = ({ isOpen, onClose, onProjectCreated, allLanguages }) => {
    const [currentStep, setCurrentStep] = useState(1);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        files: [],
        languages: []
    });

    const handleSubmit = async () => {
        setError(null);
        setIsSubmitting(true);

        try {
            // Validation finale avant soumission
            const nameError = validateProjectName(formData.name);
            const filesError = validateFiles(formData.files);
            const languagesError = validateLanguages(formData.languages);

            if (nameError || filesError || languagesError) {
                setError(nameError || filesError || languagesError);
                setIsSubmitting(false);
                return;
            }

            // Envoi de toutes les données, y compris les fichiers
            const dataToSend = {
                name: formData.name.trim(),
                languages: formData.languages,
                files: formData.files  // Ajout des fichiers
            };

            const response = await projectService.createProject(dataToSend);
            console.log(response)
            if (!response || !response.group_id) {
                throw new Error('La création du groupe de projets a échoué. Veuillez réessayer.');
            }

            setIsSubmitting(false);
            onProjectCreated?.();
            onClose();
            resetForm();
        } catch (err) {
            setError(
                err.response?.data?.message ||
                err.message ||
                'Une erreur inattendue est survenue lors de la création du groupe de projets'
            );
            setIsSubmitting(false);
        }
    };

    const resetForm = () => {
        setCurrentStep(1);
        setFormData({
            name: '',
            files: [],
            languages: []
        });
        setError(null);
    };

    const handleNext = () => {
        setError(null);
        let validationError = null;

        switch (currentStep) {
            case 1:
                validationError = validateProjectName(formData.name);
                break;
            case 2:
                validationError = validateFiles(formData.files);
                break;
            case 3:
                validationError = validateLanguages(formData.languages);
                break;
        }

        if (validationError) {
            setError(validationError);
            return;
        }

        if (currentStep < 3) {
            setCurrentStep(prev => prev + 1);
        } else {
            handleSubmit();
        }
    };

    const handlePrev = () => {
        if (currentStep > 1) {
            setCurrentStep(prev => prev - 1);
            setError(null);
        }
    };

    const handleClose = () => {
        resetForm();
        onClose();
    };

    const removeFile = (index) => {
        setFormData(prev => ({
            ...prev,
            files: prev.files.filter((_, i) => i !== index)
        }));
    };

    return (
        <div className={`modal ${isOpen ? 'active' : ''}`} onClick={handleClose}>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
                <div className="modal-header">
                    <h2 className="modal-title">Nouveau projet</h2>
                    <button
                        className="close-modal"
                        onClick={handleClose}
                        aria-label="Fermer"
                    >
                        <i className="fas fa-times"></i>
                    </button>
                </div>

                {error && (
                    <div className="notification error">
                        <i className="fas fa-exclamation-circle"></i>
                        {error}
                    </div>
                )}

                <div className="modal-steps" data-step={currentStep}>
                    <Step
                        number={1}
                        label="Informations"
                        isActive={currentStep === 1}
                        isCompleted={currentStep > 1}
                    />
                    <Step
                        number={2}
                        label="Fichiers"
                        isActive={currentStep === 2}
                        isCompleted={currentStep > 2}
                    />
                    <Step
                        number={3}
                        label="Langues"
                        isActive={currentStep === 3}
                        isCompleted={currentStep > 3}
                    />
                </div>

                <div className="modal-body">
                    <StepContent
                        step={currentStep}
                        formData={formData}
                        setFormData={setFormData}
                        onRemoveFile={removeFile}
                        allLanguages={allLanguages}
                        setError={setError}
                        maxFileSize={MAX_FILE_SIZE}
                    />
                </div>

                <div className="modal-actions">
                    {currentStep > 1 && (
                        <button
                            type="button"
                            className="btn-prev"
                            onClick={handlePrev}
                            disabled={isSubmitting}
                        >
                            Retour
                        </button>
                    )}
                    <button
                        type="button"
                        className="btn-next"
                        onClick={handleNext}
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? (
                            <>
                                <i className="fas fa-spinner fa-spin mr-2"></i>
                                Création en cours...
                            </>
                        ) : currentStep === 3 ? (
                            'Créer le projet'
                        ) : (
                            'Suivant'
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
};

const Step = ({ number, label, isActive, isCompleted }) => {
    const className = `step ${isActive ? 'active' : ''} ${isCompleted ? 'completed' : ''}`;

    return (
        <div className={className}>
            <div className="step-number">
                {!isCompleted && <span>{number}</span>}
            </div>
            <div className="step-label">{label}</div>
        </div>
    );
};

const StepContent = ({ step, formData, setFormData, onRemoveFile, allLanguages, setError, maxFileSize }) => {
    const handleFileChange = (files) => {
        const newFiles = Array.from(files);
        let errorMessage = null;

        // Validation des fichiers
        for (const file of newFiles) {
            if (file.size > maxFileSize) {
                errorMessage = `Le fichier "${file.name}" dépasse la taille maximale autorisée (100 MB)`;
                break;
            }

            const isValidType = ACCEPTED_FILE_TYPES.some(type =>
                file.type.startsWith(type) || (type.startsWith('.') && file.name.endsWith(type))
            );

            if (!isValidType) {
                errorMessage = `Le fichier "${file.name}" n'est pas dans un format audio/vidéo accepté`;
                break;
            }
        }

        if (errorMessage) {
            setError(errorMessage);
            return;
        }

        setFormData(prev => ({
            ...prev,
            files: [...prev.files, ...newFiles]
        }));
        setError(null);
    };

    const toggleLanguage = (languageCode) => {
        setFormData(prev => ({
            ...prev,
            languages: prev.languages.includes(languageCode)
                ? prev.languages.filter(code => code !== languageCode)
                : [...prev.languages, languageCode]
        }));
    };

    switch (step) {
        case 1:
            return (
                <div className="step-content active" data-step="1">
                    <div className="form-group">
                        <label className="form-label">
                            Nom du projet
                            <span className="required">*</span>
                        </label>
                        <input
                            type="text"
                            className="form-input"
                            placeholder="Entrez le nom du projet"
                            value={formData.name}
                            onChange={(e) => setFormData(prev => ({
                                ...prev,
                                name: e.target.value
                            }))}
                            maxLength={MAX_PROJECT_NAME_LENGTH}
                            aria-required="true"
                        />
                        <span className="form-help">
                            <span className="char-count">
                                {formData.name.length}/{MAX_PROJECT_NAME_LENGTH}
                            </span>
                        </span>
                    </div>
                </div>
            );

        case 2:
            return (
                <div className="step-content active" data-step="2">
                    <div className="form-group">
                        <label className="form-label">
                            Fichiers du projet
                            <span className="required">*</span>
                        </label>
                        <FileDropZone onFilesDrop={handleFileChange} />
                        <FileList
                            files={formData.files}
                            onRemoveFile={onRemoveFile}
                        />
                    </div>
                </div>
            );

        case 3:
            return (
                <div className="step-content active" data-step="3">
                    <div className="form-group">
                        <label className="form-label">
                            Langues cibles
                            <span className="required">*</span>
                        </label>
                        <p className="form-help">
                            Sélectionnez les langues pour votre projet
                        </p>
                        <LanguageGrid
                            selectedLanguages={formData.languages}
                            onToggle={toggleLanguage}
                            allLanguages={allLanguages}
                        />
                    </div>
                </div>
            );

        default:
            return null;
    }
};

const FileDropZone = ({ onFilesDrop }) => {
    const [isDragging, setIsDragging] = useState(false);

    const handleDragEnter = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setIsDragging(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);
        onFilesDrop(e.dataTransfer.files);
    };

    const handleClick = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.multiple = true;
        input.accept = 'audio/*,video/*,.wav';
        input.onchange = (e) => onFilesDrop(e.target.files);
        input.click();
    };

    return (
        <div
            className={`file-drop-zone ${isDragging ? 'dragging' : ''}`}
            onDrop={handleDrop}
            onDragOver={(e) => e.preventDefault()}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onClick={handleClick}
            role="button"
            tabIndex={0}
            aria-label="Zone de dépôt de fichiers"
        >
            <i className="fas fa-cloud-upload-alt file-drop-icon"></i>
            <p>Glissez vos fichiers audio/vidéo ici ou <strong>parcourir</strong></p>
            <span className="file-drop-help">Formats acceptés: audio et vidéo</span>
        </div>
    );
};

const FileList = ({ files, onRemoveFile }) => {
    const getFileIcon = (file) => {
        if (file.type.startsWith('audio/')) return 'fa-file-audio';
        if (file.type.startsWith('video/')) return 'fa-file-video';
        return 'fa-file';
    };

    return (
        <div className="file-list" role="list">
            {files.map((file, index) => (
                <div key={index} className="file-item" role="listitem">
                    <i className={`fas ${getFileIcon(file)}`} aria-hidden="true"></i>
                    <span className="file-name">{file.name}</span>
                    <span className="file-size">
                        {(file.size / (1024 * 1024)).toFixed(2)} MB
                    </span>
                    <button
                        type="button"
                        className="action-btn btn-secondary"
                        onClick={() => onRemoveFile(index)}
                        aria-label={`Supprimer le fichier ${file.name}`}
                    >
                        <i className="fas fa-times"></i>
                    </button>
                </div>
            ))}
            {files.length === 0 && (
                <div className="file-list-empty" role="status">
                    Aucun fichier sélectionné
                </div>
            )}
        </div>
    );
};

const LanguageGrid = ({ selectedLanguages, onToggle, allLanguages }) => {
    return (
        <div className="language-grid" role="group" aria-label="Sélection des langues">
            {allLanguages.map(language => (
                <div
                    key={language.code}
                    className={`language-option ${selectedLanguages.includes(language.code) ? 'selected' : ''}`}
                    onClick={() => onToggle(language.code)}
                    role="checkbox"
                    aria-checked={selectedLanguages.includes(language.code)}
                    tabIndex={0}
                >
                    <input
                        type="checkbox"
                        hidden
                        checked={selectedLanguages.includes(language.code)}
                        readOnly
                    />
                    <i className="fas fa-check" aria-hidden="true"></i>
                    <span>{language.name}</span>
                </div>
            ))}
        </div>
    );
};

export default NewProjectModal;