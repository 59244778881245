import React from 'react';
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import 'flag-icons/css/flag-icons.min.css';


const PRIMARY_COLOR = '#6366f1';
const SECONDARY_COLOR = '#4f46e5';

const Header = ({ projectName = "Untitled Project", language = "gb", onSave, onExport, isExporting }) => {
    return (
        <Box sx={{
            width: '100%',
            height: '60px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            bgcolor: '#2A2A2A',
            borderBottom: '5px solid #232323',
            px: 2
        }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'baseline',
                    height: '50px',
                    pr: 2,
                    mr: 2
                }}>
                    <Typography variant="h5" sx={{ color: 'white', fontWeight: 'bold', fontSize: 30, mr: 1, lineHeight: '50px' }}>
                        Sumfy.
                    </Typography>
                    <Typography variant="caption" sx={{ color: 'rgba(255,255,255,0.7)', lineHeight: '50px', fontSize: '0.75rem', position: 'relative', top: 'px' }}>
                        v1.0.0
                    </Typography>
                </Box>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                }}>

                </Box>
            </Box>
            <Box>
                <Button
                    onClick={onExport}
                    variant="outlined"
                    startIcon={isExporting ? <CircularProgress size={20} color="inherit" /> : <DownloadIcon />}
                    sx={{
                        bgcolor: PRIMARY_COLOR,
                        color: 'white',
                        '&:hover': { bgcolor: SECONDARY_COLOR },
                        mr: 1,
                        textTransform: 'none',
                        px: 2.8,
                        py: .6,
                        borderRadius: 2,
                        fontWeight: 'bold',
                        boxShadow: '0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)'
                    }}
                >
                    {isExporting ? 'Exporting...' : 'Export'}
                </Button>
            </Box>
        </Box>
    );
};

export default Header;