import React from 'react';
import './TermsOfService.css';

const TermsOfService = () => {
    return (
        <div className="terms-container">
            <div className="terms-content">
                <h1>Conditions Générales d'Utilisation</h1>
                <p className="last-updated">Dernière mise à jour : 2 Décembre 2024</p>

                <section className="terms-section">
                    <h2>1. Objet du service</h2>
                    <p>
                        Sumfy est une plateforme de doublage audio utilisant l'intelligence artificielle,
                        permettant aux utilisateurs de générer des voix synthétiques pour leurs contenus
                        dans différentes langues.
                    </p>
                </section>

                <section className="terms-section">
                    <h2>2. Acceptation des conditions</h2>
                    <p>
                        En utilisant Sumfy, vous acceptez sans réserve les présentes conditions générales
                        d'utilisation. Si vous n'acceptez pas ces conditions, vous ne pouvez pas utiliser
                        nos services.
                    </p>
                </section>

                <section className="terms-section">
                    <h2>3. Inscription et compte</h2>
                    <h3>3.1 Création de compte</h3>
                    <p>Pour utiliser Sumfy, vous devez :</p>
                    <ul>
                        <li>Être âgé d'au moins 18 ans</li>
                        <li>Fournir des informations exactes et complètes</li>
                        <li>Maintenir vos informations à jour</li>
                        <li>Protéger la confidentialité de vos identifiants</li>
                    </ul>

                    <h3>3.2 Responsabilité du compte</h3>
                    <p>
                        Vous êtes responsable de toutes les activités effectuées sous votre compte.
                        Signalez immédiatement toute utilisation non autorisée.
                    </p>
                </section>

                <section className="terms-section">
                    <h2>4. Utilisation du service</h2>
                    <h3>4.1 Contenus autorisés</h3>
                    <p>Vous vous engagez à :</p>
                    <ul>
                        <li>N'utiliser que des contenus dont vous détenez les droits</li>
                        <li>Respecter les droits de propriété intellectuelle</li>
                        <li>Ne pas utiliser le service à des fins illégales ou frauduleuses</li>
                    </ul>

                    <h3>4.2 Restrictions d'usage</h3>
                    <p>Il est interdit de :</p>
                    <ul>
                        <li>Générer des contenus diffamatoires ou offensants</li>
                        <li>Imiter des personnalités sans autorisation</li>
                        <li>Contourner les limitations techniques du service</li>
                        <li>Revendre ou redistribuer les voix générées sans autorisation</li>
                    </ul>
                </section>

                <section className="terms-section">
                    <h2>5. Tarification et paiement</h2>
                    <h3>5.2 Facturation</h3>
                    <p>
                        En développement...
                    </p>
                </section>

                <section className="terms-section">
                    <h2>6. Propriété intellectuelle</h2>
                    <h3>6.1 Droits de Sumfy</h3>
                    <p>
                        La technologie, les algorithmes et l'interface de Sumfy sont protégés par
                        la propriété intellectuelle. Leur utilisation est strictement limitée au
                        cadre du service.
                    </p>

                    <h3>6.2 Droits sur les voix générées</h3>
                    <p>
                        Les utilisateurs conservent leurs droits sur les contenus source.
                        Les voix générées peuvent être utilisées selon les conditions de
                        la licence choisie.
                    </p>
                </section>

                <section className="terms-section">
                    <h2>7. Garanties et responsabilités</h2>
                    <h3>7.1 Disponibilité du service</h3>
                    <p>
                        Sumfy s'efforce d'assurer une disponibilité maximale du service mais ne peut
                        la garantir à 100%. Des interruptions pour maintenance peuvent survenir.
                    </p>

                    <h3>7.2 Limitation de responsabilité</h3>
                    <p>
                        Sumfy ne peut être tenu responsable des dommages indirects liés à
                        l'utilisation du service ou à son indisponibilité temporaire.
                    </p>
                </section>

                <section className="terms-section">
                    <h2>8. Résiliation</h2>
                    <p>
                        Vous pouvez résilier votre compte à tout moment. Sumfy peut suspendre ou
                        supprimer un compte en cas de violation des CGU, sans remboursement.
                    </p>
                </section>

                <section className="terms-section">
                    <h2>9. Modifications des CGU</h2>
                    <p>
                        Sumfy se réserve le droit de modifier ces CGU. Les utilisateurs seront
                        informés des changements significatifs 30 jours avant leur entrée en vigueur.
                    </p>
                </section>

                <section className="terms-section">
                    <h2>10. Loi applicable</h2>
                    <p>
                        Ces CGU sont régies par le droit français. Tout litige relève de la
                        compétence des tribunaux français.
                    </p>
                </section>
            </div>
        </div>
    );
};

export default TermsOfService;