import React, { useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import emailjs from '@emailjs/browser';
import './DemoPage.css';

const DemoPage = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitError, setSubmitError] = useState('');

    const demoFeatures = [
        'Démonstration personnalisée de 30 minutes',
        'Présentation des fonctionnalités adaptée à vos besoins',
        'Test sur votre propre contenu',
        'Conseils personnalisés d\'experts',
        'Session de questions-réponses'
    ];

    const timelineSteps = [
        {
            title: '1. Prise de contact',
            description: 'Remplissez le formulaire ci-dessus. Notre équipe vous contactera sous 24h pour confirmer le rendez-vous.'
        },
        {
            title: '2. Préparation',
            description: 'Nous analysons vos besoins et préparons une démonstration personnalisée adaptée à votre cas d\'usage.'
        },
        {
            title: '3. Démonstration',
            description: 'Livraison de la démonstration, afin de tester votre contenue.'
        },
        {
            title: '4. Suivi',
            description: 'Nous vous accompagnons dans les prochaines étapes avec une proposition adaptée à vos besoins.'
        }
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setSubmitError('');

        try {
            // Préparer le template des paramètres
            const templateParams = {
                to_email: "info.sumfy@gmail.com", // Remplacez par votre email
                from_name: formData.name,
                from_email: formData.email,
                phone: formData.phone,
                message: formData.message,
            };

            // Envoyer l'email
            const response = await emailjs.send(
                'service_gk6xkgt', // Remplacez par votre Service ID
                'template_efs592q', // Remplacez par votre Template ID
                templateParams,
                '3MQ4M7xwveC3cwNfn' // Remplacez par votre Public Key
            );

            if (response.status === 200) {
                alert('Merci pour votre demande! Notre équipe vous contactera sous 24h.');
                // Réinitialiser le formulaire
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    message: ''
                });
            }
        } catch (error) {
            console.error('Erreur lors de l\'envoi:', error);
            setSubmitError('Une erreur est survenue lors de l\'envoi du formulaire. Veuillez réessayer.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="demo-page">
            <div className="glass-bg">
                <div className="glass-blob blob-1"></div>
                <div className="glass-blob blob-2"></div>
            </div>

            <Navbar />

            <div className="demo-container">
                <div className="demo-content">
                    <h1>Découvrez la puissance du doublage IA</h1>
                    <p>Réservez une démonstration personnalisée et découvrez comment Sumfy peut transformer votre contenu pour une audience internationale.</p>
                    <ul className="demo-features">
                        {demoFeatures.map((feature, index) => (
                            <li key={index}>
                                <i className="fas fa-check-circle"></i>
                                {feature}
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="demo-form-container">
                    <form onSubmit={handleSubmit} id="demo-form">
                        <div className="form-group">
                            <label htmlFor="name">Nom complet</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                required
                                placeholder="John Doe"
                                value={formData.name}
                                onChange={handleChange}
                            />
                        </div>


                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                required
                                placeholder="john@entreprise.com"
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="phone">Téléphone</label>
                            <input
                                type="tel"
                                id="phone"
                                name="phone"
                                placeholder="+33 6 12 34 56 78"
                                value={formData.phone}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="message">Message (optionnel)</label>
                            <textarea
                                id="message"
                                name="message"
                                rows="4"
                                placeholder="Partagez-nous vos besoins spécifiques..."
                                value={formData.message}
                                onChange={handleChange}
                            ></textarea>
                        </div>

                        {submitError && (
                            <div className="error-message">
                                {submitError}
                            </div>
                        )}

                        <button
                            type="submit"
                            className="form-submit"
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? 'Envoi en cours...' : 'Réserver ma démo'}
                        </button>
                    </form>
                </div>
            </div>

            <section className="timeline-section">
                <div className="timeline-container">
                    <div className="timeline-header">
                        <h2>Le processus de démonstration</h2>
                        <p>Un parcours simple en 4 étapes pour découvrir Sumfy</p>
                    </div>

                    <div className="timeline">
                        {timelineSteps.map((step, index) => (
                            <div key={index} className="timeline-item">
                                <div className="timeline-content">
                                    <h3>{step.title}</h3>
                                    <p>{step.description}</p>
                                </div>
                                <div className="timeline-dot"></div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
};

export default DemoPage;