import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './pages/LoginPage/LoginPage.jsx';
import LandingPage from './pages/LandingPage/LandingPage.jsx';
import FeaturesPage from './pages/FeaturesPage/FeaturesPage.jsx';
import DemoPage from './pages/DemoPage/DemoPage.jsx';
import PricingPage from './pages/PricingPage/PricingPage.jsx';
import DashboardPage from './pages/DashboardPage/DashboadPage.jsx';
import SettingsPage from './pages/SettingPage/SettingsPage.jsx';
import MainPage from './pages/EditingSoftware/MainPage.js';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy.jsx';
import TermsOfService from './pages/TermsOfService/TermsOfService.jsx';
import CookiePolicy from './pages/CookiePolicy/CookiePolicy.jsx';
import LegalNotice from './pages/LegalNotice/LegalNotice.jsx';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/features" element={<FeaturesPage />} />
          <Route path="/demo" element={<DemoPage />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/settings" element={<SettingsPage />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<TermsOfService />} />
          <Route path="/cookies" element={<CookiePolicy />} />
          <Route path="/legal" element={<LegalNotice />} />
          <Route path="/main/:projectId" element={<MainPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;