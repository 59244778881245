import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
    return (
        <nav className="navbar">
            <Link to="/" className="logo">Sumfy.</Link>
            <div className="nav-links">
                <Link to="/features">Fonctionnalités</Link>
                <Link to="/demo">Démo</Link>
                {/*<Link to="/pricing">Tarifs</Link>*/}
                <Link to="/login" className="login-btn">Se connecter</Link>
            </div>
        </nav>
    );
};

export default Navbar;