import { Link } from 'react-router-dom';
import TikTokComparison from '../TiktokComparaison/TiktokComparaison';
import './Hero.css';

export default function Hero() {
    return (
        <section className="hero">
            <div className="hero-content">
                <div className="hero-text">
                    <h1 className="hero-title">
                        L'IA qui donne une nouvelle voix à vos contenus
                    </h1>
                    <p className="hero-description">
                        Transformez votre contenu avec notre technologie de doublage IA
                        de nouvelle génération. Une qualité studio en quelques clics.
                    </p>
                    <Link to="demo" className="hero-cta">
                        <i className="fas fa-calendar-check"></i>
                        Réserver une démo
                    </Link>
                </div>

                <div className="hero-preview">
                    <TikTokComparison videoUrl="https://images.unsplash.com/photo-1522529599102-193c0d76b5b6" />
                </div>
            </div>
        </section>
    );
}