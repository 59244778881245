// src/pages/FeaturesPage/FeaturesPage.jsx
import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import './FeaturesPage.css';

const FeaturesPage = () => {
    const features = [
        {
            title: "Studio de montage intégré",
            description: "Éditez vos vidéos directement dans Sumfy avec notre interface simple et intuitive.",
            image: "/image/logiciel de montage.jpg",
            imageAlt: "Studio de montage Sumfy",
            list: [
                { icon: "cut", text: "Découpage et assemblage précis" },
                { icon: "layer-group", text: "Timeline multi-pistes audio et vidéo" },
                { icon: "sliders-h", text: "Ajustements audio avancés" },
                { icon: "crop", text: "Recadrage et ajustement vidéo" }
            ]
        },
        {
            title: "IA de doublage nouvelle génération",
            description: "Notre technologie de synthèse vocale produit des voix naturelles et expressives dans plus de 40 langues.",
            image: "/image/old-microphone-6279518_1920.jpg",
            imageAlt: "IA de doublage Sumfy",
            list: [
                { icon: "microphone", text: "Voix naturelles et expressives" },
                { icon: "sync", text: "Synchronisation des voix automatique" },
                { icon: "theater-masks", text: "Adaptation des émotions" },
                { icon: "language", text: "Support de 40+ langues" }
            ]
        },
        {
            title: "Export professionnel",
            description: "Exportez vos contenus dans différents formats avec une qualité optimale.",
            image: "/image/mixer-6857358_1920.jpg",
            imageAlt: "Export Sumfy",
            list: [
                { icon: "file-export", text: "Export en haute qualité" },
                { icon: "compress-arrows-alt", text: "Compression intelligente" },
                { icon: "download", text: "Téléchargement rapide" },
                { icon: "hdd", text: "Stockage sécurisé" }
            ]
        }
    ];

    return (
        <div className="features-page">
            <div className="glass-bg">
                <div className="glass-blob blob-1"></div>
                <div className="glass-blob blob-2"></div>
            </div>

            <Navbar />

            <header className="features-header">
                <h1>Des outils puissants pour votre contenu</h1>
                <p>Découvrez toutes les fonctionnalités qui font de Sumfy la solution la plus complète pour le doublage IA</p>
            </header>

            <main>
                {features.map((feature, index) => (
                    <section key={index} className="feature-section">
                        <div className="feature-image">
                            <img src={feature.image} alt={feature.imageAlt} />
                        </div>
                        <div className="feature-content">
                            <h2>{feature.title}</h2>
                            <p>{feature.description}</p>
                            <ul className="feature-list">
                                {feature.list.map((item, itemIndex) => (
                                    <li key={itemIndex}>
                                        <i className={`fas fa-${item.icon}`}></i>
                                        {item.text}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </section>
                ))}
            </main>

            <Footer />
        </div>
    );
};

export default FeaturesPage;