import React, { useState, useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import axios from 'axios';

const WaveformDisplay = React.forwardRef(({ waveformFile, color, projectId, trimStart, trimEnd }, ref) => {
    const [waveformUrl, setWaveformUrl] = useState(null);
    const [error, setError] = useState(null);
    const canvasRef = useRef(null);
    const imageRef = useRef(null);

    useEffect(() => {
        const fetchWaveformUrl = async () => {
            if (!waveformFile || !projectId) {
                setError("Missing waveform file or project ID");
                return;
            }

            try {
                const response = await axios.get(`http://localhost:5000/get_file/${projectId}/${waveformFile}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    }
                });

                if (response.data && response.data.url) {
                    setWaveformUrl(response.data.url);
                } else {
                    setError("Invalid response format");
                }
            } catch (error) {
                console.error("Error fetching waveform URL:", error);
                setError(error.message || "Failed to fetch waveform");
            }
        };

        fetchWaveformUrl();
    }, [waveformFile, projectId]);

    useEffect(() => {
        if (waveformUrl && canvasRef.current && imageRef.current) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');
            const image = imageRef.current;

            image.onload = () => {
                canvas.width = image.width;
                canvas.height = image.height;

                // Draw the original image
                ctx.drawImage(image, 0, 0);

                // Apply trimming effect
                if (trimStart > 0 || trimEnd > 0) {
                    ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';

                    // Left trim
                    if (trimStart > 0) {
                        const trimWidth = canvas.width * trimStart;
                        ctx.fillRect(0, 0, trimWidth, canvas.height);
                    }

                    // Right trim
                    if (trimEnd > 0) {
                        const trimWidth = canvas.width * trimEnd;
                        ctx.fillRect(canvas.width - trimWidth, 0, trimWidth, canvas.height);
                    }
                }
            };
        }
    }, [waveformUrl, trimStart, trimEnd]);

    if (error) {
        return (
            <Box
                sx={{
                    position: 'absolute',
                    top: 5,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'red',
                    fontSize: '10px',
                }}
            >
                Error: {error}
            </Box>
        );
    }

    if (!waveformUrl) {
        return (
            <Box
                sx={{
                    position: 'absolute',
                    top: 5,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'white',
                    fontSize: '10px',
                }}
            >
                Loading...
            </Box>
        );
    }

    return (
        <>
            <Box
                component="img"
                ref={imageRef}
                src={waveformUrl}
                alt="Waveform"
                sx={{
                    display: 'none', // Hide the original image
                }}
                onError={(e) => {
                    console.error("Error loading waveform image:", e);
                    setError("Failed to load waveform image");
                }}
            />
            <Box
                component="canvas"
                ref={(node) => {
                    canvasRef.current = node;
                    if (typeof ref === 'function') ref(node);
                    else if (ref) ref.current = node;
                }}
                sx={{
                    position: 'absolute',
                    top: 5,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'fill',
                    backgroundColor: 'transparent',
                    pointerEvents: 'none',
                }}
            />
        </>
    );
});

export default WaveformDisplay;