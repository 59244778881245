import { useState, useRef, useEffect } from 'react';
import { Heart, MessageCircle, Share2, Play, Pause } from 'lucide-react';
import * as Slider from '@radix-ui/react-slider';
import { styled } from 'styled-components';

const Container = styled.div`
  max-width: 360px;
  width: 100%;
`;

const Video = styled.div`
  background-color: #000;
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
`;

const AspectRatio = styled.div`
  position: relative;
  padding-bottom: 177.78%;
`;

const Content = styled.div`
  position: absolute;
  inset: 0;
`;

const Overlay = styled.div`
  position: relative;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(126, 34, 206, 0.1),
    rgba(0, 0, 0, 0) 40%,
    rgba(0, 0, 0, 0.4) 80%,
    rgba(0, 0, 0, 0.5) 100%
  );
`;

const VideoElement = styled.video`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Controls = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    to bottom,
    transparent 70%,
    rgba(0, 0, 0, 0.6) 100%
  );
`;

const SocialButtons = styled.div`
  position: absolute;
  right: 1rem;
  bottom: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

const SocialButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
  background: none;
  border: none;
  cursor: pointer;
  width: 2rem;
`;

const IconContainer = styled.div`
  width: 3rem;
  height: 3rem;
  border-radius: 9999px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Stat = styled.span`
  color: white;
  font-size: 0.75rem;
`;

const BottomControls = styled.div`
  padding: 1rem;
  padding-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-top: auto;
  width: 100%;
  box-sizing: border-box;
`;

const Playback = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const PlayButton = styled.button`
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
  background: none;
  border: none;
  cursor: pointer;
  color: white;
`;

const Languages = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.25rem;
  width: 100%;
  overflow: hidden;
`;

const LanguageButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0.6rem 0.95rem;
  border-radius: 9999px;
  margin-bottom: 1rem;
  background-color: ${props => props.active ? '#8B5CF6' : 'rgba(255, 255, 255, 0.2)'};
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 0.875rem;

  &:hover {
    background-color: ${props => props.active ? '#8B5CF6' : 'rgba(255, 255, 255, 0.3)'};
  }
`;

function TikTokComparaison() {
  const [isPlaying, setIsPlaying] = useState(false);
  const [activeLanguage, setActiveLanguage] = useState('en');
  const [progress, setProgress] = useState(0);
  const videoRef = useRef(null);

  const videos = {
    en: { url: '/video/en.mp4', likes: '15.7K', comments: '834' },
    fr: { url: '/video/fr.mp4', likes: '18.2K', comments: '956' },
    es: { url: '/video/es.mp4', likes: '23.4K', comments: '1.2K' },
  };

  const languages = [
    { code: 'en', name: 'English', flag: '🇬🇧' },
    { code: 'fr', name: 'French', flag: '🇫🇷' },
    { code: 'es', name: 'Spanish', flag: '🇪🇸' },
  ];

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      if (isPlaying) {
        video.play().catch(() => setIsPlaying(false));
      } else {
        video.pause();
      }
    }
  }, [isPlaying]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.src = videos[activeLanguage].url;
      videoRef.current.load();
      setProgress(0);
      setIsPlaying(false);
      // Ajouter un événement loadeddata pour définir le temps de départ
      videoRef.current.addEventListener('loadeddata', () => {
        videoRef.current.currentTime = 0;
      }, { once: true }); // L'événement ne sera déclenché qu'une fois
    }
  }, [activeLanguage]);

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      const progress = (videoRef.current.currentTime / videoRef.current.duration) * 100;
      setProgress(progress);
    }
  };

  const handleSliderChange = (value) => {
    const newValue = value[0];
    setProgress(newValue);
    if (videoRef.current) {
      videoRef.current.currentTime = (newValue / 100) * videoRef.current.duration;
    }
  };

  return (
    <Container>
      <Video>
        <AspectRatio>
          <Content>
            <Overlay>
              <VideoElement
                ref={videoRef}
                onTimeUpdate={handleTimeUpdate}
                playsInline
              />
              <Controls>
                <SocialButtons>
                  <SocialButton>
                    <IconContainer>
                      <Heart size={24} color="white" />
                    </IconContainer>
                    <Stat>{videos[activeLanguage].likes}</Stat>
                  </SocialButton>

                  <SocialButton>
                    <IconContainer>
                      <MessageCircle size={24} color="white" />
                    </IconContainer>
                    <Stat>{videos[activeLanguage].comments}</Stat>
                  </SocialButton>

                  <SocialButton>
                    <IconContainer>
                      <Share2 size={24} color="white" />
                    </IconContainer>
                    <Stat>Share</Stat>
                  </SocialButton>
                </SocialButtons>

                <BottomControls>
                  <Playback>
                    <PlayButton onClick={() => setIsPlaying(!isPlaying)}>
                      {isPlaying ? <Pause size={32} /> : <Play size={32} />}
                    </PlayButton>
                    <Slider.Root
                      style={{
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        height: '2rem'
                      }}
                      value={[progress]}
                      onValueChange={handleSliderChange}
                      max={100}
                      step={1}
                    >
                      <Slider.Track
                        style={{
                          position: 'relative',
                          flexGrow: 1,
                          height: '0.25rem',
                          backgroundColor: 'rgba(255, 255, 255, 0.3)',
                          borderRadius: '9999px'
                        }}
                      >
                        <Slider.Range
                          style={{
                            position: 'absolute',
                            height: '100%',
                            backgroundColor: '#8B5CF6',
                            borderRadius: '9999px'
                          }}
                        />
                      </Slider.Track>
                      <Slider.Thumb
                        style={{
                          display: 'block',
                          width: '1rem',
                          height: '1rem',
                          backgroundColor: '#8B5CF6',
                          boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
                          borderRadius: '9999px',
                          cursor: 'pointer'
                        }}
                      />
                    </Slider.Root>
                  </Playback>

                  <Languages>
                    {languages.map((lang) => (
                      <LanguageButton
                        key={lang.code}
                        active={activeLanguage === lang.code}
                        onClick={() => setActiveLanguage(lang.code)}
                      >
                        <span>{lang.flag}</span>
                        <span>{lang.name}</span>
                      </LanguageButton>
                    ))}
                  </Languages>
                </BottomControls>
              </Controls>
            </Overlay>
          </Content>
        </AspectRatio>
      </Video>
    </Container>
  );
}

export default TikTokComparaison;