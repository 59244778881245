// src/components/Dashboard/Sidebar.jsx
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Sidebar.css';

const Sidebar = () => {
    const location = useLocation();

    return (
        <aside className="sidebar">
            <Link to="/" className="sidebar-logo">
                Sumfy.
            </Link>
            <nav>
                <ul className="nav-menu">
                    <li className="nav-item">
                        <Link to="/dashboard" className={`nav-link ${location.pathname === '/dashboard' ? 'active' : ''}`}>
                            <i className="fas fa-folder"></i>
                            Mes Projets
                        </Link>
                    </li>
                    <li className="nav-item">
                        <span className="nav-link disabled">
                            <i className="fas fa-chart-bar"></i>
                            Statistiques
                            <i className="fas fa-lock lock-icon"></i>
                        </span>
                    </li>
                    <li className="nav-item">
                        <Link to="/settings" className={`nav-link ${location.pathname === '/settings' ? 'active' : ''}`}>
                            <i className="fas fa-cog"></i>
                            Paramètres
                        </Link>
                    </li>
                </ul>
            </nav>
        </aside>
    );
};

export default Sidebar;