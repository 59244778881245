// src/components/Features/Features.jsx
import React from 'react';
import './Features.css';

const Features = () => {
    const features = [
        {
            icon: 'bullseye',
            title: 'Précision IA',
            description: 'Notre technologie de pointe garantit une synchronisation parfaite et une intonation naturelle dans plus de 40 langues.'
        },
        {
            icon: 'bolt',
            title: 'Ultra Rapide',
            description: 'Générez du contenu doublé en quelques minutes. 100x plus rapide que les méthodes traditionnelles.'
        },
        {
            icon: 'star',
            title: 'Qualité Studio',
            description: 'Une qualité audio professionnelle grâce à nos algorithmes de traitement dernière génération.'
        }
    ];

    return (
        <section className="features">
            <div className="features-grid">
                {features.map((feature, index) => (
                    <div key={index} className="feature-card">
                        <div className="feature-icon">
                            <i className={`fas fa-${feature.icon}`}></i>
                        </div>
                        <h3>{feature.title}</h3>
                        <p>{feature.description}</p>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Features;