import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import './PricingPage.css';

const PricingPage = () => {
    const [isAnnual, setIsAnnual] = useState(true);

    const calculatePrice = (annualPrice) => {
        if (typeof annualPrice === 'string' && annualPrice === "Sur mesure") {
            return "Sur mesure";
        }
        const annualNum = parseInt(annualPrice);
        // Le prix mensuel est 25% plus élevé que le prix annuel (donc le prix annuel est -20% du mensuel)
        const monthlyPrice = Math.round(annualNum * 1.25);
        return isAnnual ? annualPrice : monthlyPrice.toString();
    };

    const plans = [
        {
            name: "Starter",
            price: calculatePrice("29"),
            features: [
                "5 heures de doublage",
                "10 langues disponibles",
                "Support par email",
                "API basique",
                "Export MP3"
            ],
            buttonText: "Démarrer gratuitement",
            buttonStyle: "secondary",
            popular: false
        },
        {
            name: "Pro",
            price: calculatePrice("89"),
            features: [
                "20 heures de doublage",
                "Toutes les langues",
                "Support prioritaire",
                "API complète",
                "Export multiple formats",
                "Synchronisation automatique"
            ],
            buttonText: "Commencer maintenant",
            buttonStyle: "primary",
            popular: true
        },
        {
            name: "Enterprise",
            price: "Sur mesure",
            features: [
                "Volume illimité",
                "Voix personnalisées",
                "Account manager dédié",
                "Formations sur mesure",
                "Intégration personnalisée"
            ],
            buttonText: "Contacter les ventes",
            buttonStyle: "secondary",
            popular: false
        }
    ];

    const faqs = [
        {
            question: "Comment fonctionne la facturation ?",
            answer: "La facturation est mensuelle ou annuelle selon votre choix. L'engagement annuel vous permet de bénéficier d'une remise de 20% sur le tarif mensuel."
        },
        {
            question: "Puis-je changer de forfait ?",
            answer: "Vous pouvez upgrader ou downgrader votre forfait à tout moment. Les ajustements de facturation seront calculés au prorata."
        },
        {
            question: "Y a-t-il une période d'essai ?",
            answer: "Oui, tous nos forfaits incluent une période d'essai de 14 jours sans engagement. Vous pouvez tester toutes les fonctionnalités avant de vous engager."
        },
        {
            question: "Quels sont les formats d'export supportés ?",
            answer: "Nous supportons l'export en MP3, WAV, AAC et FLAC. Les forfaits Pro et Enterprise permettent également des formats personnalisés."
        }
    ];

    return (
        <div className="pricing-page">
            <div className="pricing-page__glass-bg">
                <div className="pricing-page__blob pricing-page__blob-1"></div>
                <div className="pricing-page__blob pricing-page__blob-2"></div>
            </div>

            <Navbar />

            <div className="pricing-page__header">
                <h1 className="pricing-page__title">Des forfaits adaptés à vos besoins</h1>
                <p className="pricing-page__subtitle">
                    Choisissez le plan qui correspond le mieux à votre utilisation et développez votre audience internationale
                </p>
                <div className="pricing-page__toggle">
                    <button
                        className={`pricing-page__toggle-button ${!isAnnual ? 'pricing-page__toggle-button--active' : ''}`}
                        onClick={() => setIsAnnual(false)}
                    >
                        Mensuel
                    </button>
                    <button
                        className={`pricing-page__toggle-button ${isAnnual ? 'pricing-page__toggle-button--active' : ''}`}
                        onClick={() => setIsAnnual(true)}
                    >
                        Annuel (-20%)
                    </button>
                </div>
            </div>

            <div className="pricing-page__grid">
                {plans.map((plan, index) => (
                    <div
                        key={index}
                        className={`pricing-page__card ${plan.popular ? 'pricing-page__card--popular' : ''}`}
                    >
                        {plan.popular && (
                            <div className="pricing-page__popular-badge">Plus populaire</div>
                        )}
                        <h3 className="pricing-page__card-title">{plan.name}</h3>
                        <div className={`pricing-page__price ${plan.name === 'Enterprise' ? 'pricing-page__price--custom' : ''}`}>
                            {plan.price === "Sur mesure" ? (
                                plan.price
                            ) : (
                                <>
                                    {plan.price}€ <span>/mois</span>
                                </>
                            )}
                        </div>
                        <ul className="pricing-page__features">
                            {plan.features.map((feature, featureIndex) => (
                                <li key={featureIndex} className="pricing-page__feature">
                                    <i className="fas fa-check"></i>
                                    {feature}
                                </li>
                            ))}
                        </ul>
                        <Link
                            to="#"
                            className={`pricing-page__button pricing-page__button--${plan.buttonStyle}`}
                        >
                            {plan.buttonText}
                        </Link>
                    </div>
                ))}
            </div>

            <section className="pricing-page__faq">
                <h2 className="pricing-page__faq-title">Questions fréquentes</h2>
                {faqs.map((faq, index) => (
                    <div key={index} className="pricing-page__faq-item">
                        <h3 className="pricing-page__faq-question">{faq.question}</h3>
                        <p className="pricing-page__faq-answer">{faq.answer}</p>
                    </div>
                ))}
            </section>

            <Footer />
        </div>
    );
};

export default PricingPage;