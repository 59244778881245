import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
    return (
        <div className="privacy-container">
            <div className="privacy-content">
                <h1>Politique de Confidentialité</h1>
                <p className="last-updated">Dernière mise à jour : 2 Décembre 2024</p>

                <section className="privacy-section">
                    <h2>1. Introduction</h2>
                    <p>
                        Chez Sumfy, nous accordons une importance capitale à la protection de vos données personnelles
                        et de vos contenus audio. Cette politique de confidentialité explique comment nous collectons,
                        utilisons et protégeons vos informations lors de l'utilisation de notre service de doublage audio IA.
                    </p>
                </section>

                <section className="privacy-section">
                    <h2>2. Données collectées</h2>
                    <h3>2.1 Informations personnelles</h3>
                    <ul>
                        <li>Informations de compte (nom, email, mot de passe crypté)</li>
                        <li>Informations de facturation</li>
                        <li>Préférences linguistiques</li>
                    </ul>

                    <h3>2.2 Contenus utilisateur</h3>
                    <ul>
                        <li>Fichiers audio source</li>
                        <li>Transcriptions et traductions</li>
                        <li>Voix générées et métadonnées associées</li>
                    </ul>

                    <h3>2.3 Données techniques</h3>
                    <ul>
                        <li>Adresse IP et données de connexion</li>
                        <li>Informations sur le navigateur et l'appareil</li>
                        <li>Statistiques d'utilisation du service</li>
                    </ul>
                </section>

                <section className="privacy-section">
                    <h2>3. Utilisation des données</h2>
                    <h3>3.1 Traitement des contenus audio</h3>
                    <p>
                        Vos fichiers audio sont utilisés uniquement pour :
                    </p>
                    <ul>
                        <li>Générer les voix de doublage demandées</li>
                        <li>Améliorer la qualité de nos modèles IA (après anonymisation)</li>
                        <li>Assurer le support technique si nécessaire</li>
                    </ul>

                    <h3>3.2 Amélioration du service</h3>
                    <p>
                        Nous analysons les données d'utilisation pour :
                    </p>
                    <ul>
                        <li>Optimiser nos algorithmes de génération vocale</li>
                        <li>Personnaliser votre expérience utilisateur</li>
                        <li>Développer de nouvelles fonctionnalités</li>
                    </ul>
                </section>

                <section className="privacy-section">
                    <h2>4. Protection des données</h2>
                    <p>
                        Nous mettons en œuvre des mesures de sécurité strictes pour protéger vos données :
                    </p>
                    <ul>
                        <li>Chiffrement des fichiers audio en transit et au repos</li>
                        <li>Accès restreint aux données sur base du besoin</li>
                        <li>Surveillance continue des systèmes</li>
                        <li>Audits de sécurité réguliers</li>
                    </ul>
                </section>

                <section className="privacy-section">
                    <h2>5. Conservation des données</h2>
                    <ul>
                        <li>Les fichiers audio source sont conservés 30 jours après le dernier accès</li>
                        <li>Les voix générées sont stockées selon votre plan d'abonnement</li>
                        <li>Les données de compte sont conservées tant que votre compte est actif</li>
                    </ul>
                </section>

                <section className="privacy-section">
                    <h2>6. Vos droits</h2>
                    <p>
                        Conformément au RGPD, vous disposez des droits suivants :
                    </p>
                    <ul>
                        <li>Accéder à vos données personnelles</li>
                        <li>Rectifier vos informations</li>
                        <li>Supprimer vos données</li>
                        <li>Exporter vos données</li>
                        <li>Retirer votre consentement</li>
                    </ul>
                </section>
            </div>
        </div>
    );
};

export default PrivacyPolicy;