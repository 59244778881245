import React from 'react';
import './CookiePolicy.css';

const CookiePolicy = () => {
    return (
        <div className="cookie-container">
            <div className="cookie-content">
                <h1>Politique des Cookies</h1>
                <p className="last-updated">Dernière mise à jour : 2 Décembre 2024</p>

                <section className="cookie-section">
                    <h2>1. Qu'est-ce qu'un cookie ?</h2>
                    <p>
                        Un cookie est un petit fichier texte déposé sur votre terminal (ordinateur, mobile, tablette)
                        lors de votre visite sur notre site. Les cookies nous permettent d'améliorer votre
                        expérience utilisateur et de personnaliser nos services.
                    </p>
                </section>

                <section className="cookie-section">
                    <h2>2. Les cookies que nous utilisons</h2>

                    <h3>2.1 Cookies essentiels</h3>
                    <p>Nécessaires au fonctionnement du site :</p>
                    <ul>
                        <li>Session utilisateur</li>
                        <li>Authentification sécurisée</li>
                        <li>Préférences de langue</li>
                        <li>Configuration de base</li>
                    </ul>

                    <h3>2.2 Cookies de performance</h3>
                    <p>Pour améliorer notre service :</p>
                    <ul>
                        <li>Statistiques d'utilisation</li>
                        <li>Temps de chargement</li>
                        <li>Détection des erreurs</li>
                        <li>Performance du lecteur audio</li>
                    </ul>

                    <h3>2.3 Cookies de fonctionnalité</h3>
                    <p>Pour personnaliser votre expérience :</p>
                    <ul>
                        <li>Préférences de voix</li>
                        <li>Paramètres de l'éditeur audio</li>
                        <li>Historique des projets</li>
                        <li>Configurations personnalisées</li>
                    </ul>

                    <h3>2.4 Cookies de ciblage</h3>
                    <p>Pour la personnalisation marketing :</p>
                    <ul>
                        <li>Préférences de contenu</li>
                        <li>Analyse des comportements</li>
                        <li>Mesure de performance des campagnes</li>
                    </ul>
                </section>

                <section className="cookie-section">
                    <h2>3. Durée de conservation</h2>
                    <ul>
                        <li>Cookies de session : supprimés à la fermeture du navigateur</li>
                        <li>Cookies persistants : maximum 13 mois</li>
                        <li>Cookies de préférences : 6 mois</li>
                        <li>Cookies d'analyse : 12 mois</li>
                    </ul>
                </section>

                <section className="cookie-section">
                    <h2>4. Gestion des cookies</h2>
                    <h3>4.1 Notre gestionnaire de consentement</h3>
                    <p>
                        Lors de votre première visite, une bannière vous permet de choisir les cookies
                        que vous acceptez. Vous pouvez modifier vos choix à tout moment via le bouton
                        "Gérer les cookies" en bas de page.
                    </p>

                    <h3>4.2 Paramètres du navigateur</h3>
                    <p>
                        Vous pouvez également configurer votre navigateur pour gérer les cookies :
                    </p>
                    <ul>
                        <li>{"Chrome : Menu > Paramètres > Confidentialité et sécurité"}</li>
                        <li>{"Firefox : Menu > Options > Vie privée et sécurité"}</li>
                        <li>{"Safari : Préférences > Confidentialité"}</li>
                        <li>{"Edge : Paramètres > Confidentialité et services"}</li>
                    </ul>
                </section>

                <section className="cookie-section">
                    <h2>5. Cookies tiers</h2>
                    <p>
                        Nous utilisons des services tiers qui peuvent déposer des cookies :
                    </p>
                    <ul>
                        <li>Google Analytics : analyse d'audience</li>
                        <li>Cloudflare : sécurité et performance</li>
                        <li>Intercom : support client</li>
                    </ul>
                </section>

                <section className="cookie-section">
                    <h2>6. Impact sur l'expérience utilisateur</h2>
                    <p>
                        Le refus des cookies non essentiels peut limiter certaines fonctionnalités :
                    </p>
                    <ul>
                        <li>Personnalisation de l'interface</li>
                        <li>Sauvegarde des préférences audio</li>
                        <li>Historique des projets</li>
                        <li>Recommandations personnalisées</li>
                    </ul>
                </section>
            </div>
        </div>
    );
};

export default CookiePolicy;