import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { projectService } from '../../services/project.service.js';
import './ProjectGrid.css';

const Modal = ({ isOpen, onClose, project, onLanguageSelect }) => {
    if (!isOpen || !project) return null;

    const handleBackdropClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <div className="language_select_modal" onClick={handleBackdropClick}>
            <div className="language_select_content">
                <div className="language_select_header">
                    <h2 className="language_select_title">Éditer le projet</h2>
                    <button className="language_select_close" onClick={onClose}>
                        <i className="fas fa-times"></i>
                    </button>
                </div>

                <div className="language_select_description">
                    <div className="language_select_info">
                        <i className="fas fa-info-circle"></i>
                        <p>Sélectionnez la langue dans laquelle vous souhaitez éditer ce projet</p>
                    </div>
                </div>

                <div className="language_select_grid">
                    {project.projects.map(project => (
                        <div
                            key={project.id}
                            className="language_select_option"
                            onClick={() => onLanguageSelect(project.id)}
                        >
                            <div className="language_select_icon">
                                <i className="fas fa-language"></i>
                            </div>
                            <div className="language_select_details">
                                <span className="language_select_name">{project.language_name}</span>
                                <span className="language_select_label">Cliquez pour éditer</span>
                            </div>
                            <i className="fas fa-chevron-right language_select_arrow"></i>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

const ProjectCard = ({ group, onDelete, onEdit }) => {
    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString('fr-FR', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    };

    const getStatusText = (status) => {
        switch (status) {
            case 'completed':
                return 'Terminé';
            case 'failed':
                return 'Échoué';
            default:
                return 'En traitement';
        }
    };

    const isActionable = group.status === 'completed' || group.status === 'failed';

    const handleDelete = (e) => {
        e.stopPropagation();
        if (isActionable) {
            onDelete();
        }
    };

    return (
        <div className="project-card">
            <div className="project-header">
                <div className="project-info">
                    <h3>{group.project_name}</h3>
                </div>
                <span className={`project-status status-${group.status || 'progress'}`}>
                    {getStatusText(group.status)}
                </span>
            </div>
            <div className="project-meta">
                <i className="far fa-calendar"></i>
                Créé le {formatDate(group.created_at)}
            </div>
            <div className="project-languages">
                {group.projects.map(project => (
                    <span key={project.id} className="language-tag">
                        {project.language_name}
                    </span>
                ))}
            </div>
            <div className="card-actions">
                <button
                    className="action-btn btn-primary"
                    onClick={onEdit}
                    disabled={!isActionable}
                    title={!isActionable ? "L'édition n'est pas disponible pendant le traitement" : "Éditer le projet"}
                >
                    <i className="fas fa-edit"></i>
                    Éditer
                </button>
                <button
                    className="action-btn btn-secondary"
                    onClick={handleDelete}
                    disabled={!isActionable}
                    title={!isActionable ? "La suppression n'est pas disponible pendant le traitement" : "Supprimer le projet"}
                >
                    <i className="fas fa-trash"></i>
                </button>
            </div>
        </div>
    );
};

const ProjectGrid = ({ shouldRefresh, onRefreshComplete, filters }) => {
    const navigate = useNavigate();
    const [groups, setGroups] = useState([]);
    const [filteredGroups, setFilteredGroups] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);

    const loadGroups = async () => {
        try {
            setLoading(true);
            const data = await projectService.getAllProjects();
            setGroups(data);
            setFilteredGroups(data);
            setError(null);
        } catch (err) {
            setError('Impossible de charger les projets');
            console.error(err);
        } finally {
            setLoading(false);
            if (onRefreshComplete) {
                onRefreshComplete();
            }
        }
    };

    useEffect(() => {
        loadGroups();
    }, []);

    useEffect(() => {
        if (shouldRefresh) {
            loadGroups();
        }
    }, [shouldRefresh]);

    // Mise à jour de la logique de filtrage
    useEffect(() => {
        let result = [...groups];

        // Filtre par recherche
        if (filters?.search) {
            const searchLower = filters.search.toLowerCase();
            result = result.filter(group =>
                group.project_name.toLowerCase().includes(searchLower)
            );
        }

        // Filtre par statuts (multi-sélection)
        if (filters?.statuses && filters.statuses.length > 0) {
            result = result.filter(group =>
                filters.statuses.includes(group.status)
            );
        }

        // Filtre par langages (multi-sélection)
        if (filters?.languages && filters.languages.length > 0) {
            result = result.filter(group =>
                group.projects.some(project =>
                    filters.languages.includes(project.language_code)
                )
            );
        }

        setFilteredGroups(result);
    }, [filters, groups]);

    const handleDeleteGroup = async (groupId) => {
        if (window.confirm('Êtes-vous sûr de vouloir supprimer ce groupe de projets ?')) {
            try {
                await projectService.deleteProject(groupId);
                setGroups(prevGroups => prevGroups.filter(g => g.group_id !== groupId));
                setFilteredGroups(prevGroups => prevGroups.filter(g => g.group_id !== groupId));
            } catch (err) {
                console.error('Erreur lors de la suppression:', err);
            }
        }
    };

    const handleEdit = (project) => {
        setSelectedProject(project);
        setModalOpen(true);
    };

    const handleLanguageSelect = (projectId) => {
        navigate(`/main/${projectId}`);
        setModalOpen(false);
    };

    if (loading) return <div className="loading">Chargement des projets...</div>;
    if (error) return <div className="error">{error}</div>;
    if (filteredGroups.length === 0) {
        return (
            <div className="no-results">
                <i className="fas fa-search"></i>
                <p>Aucun projet ne correspond à vos critères de recherche</p>
            </div>
        );
    }

    return (
        <>
            <div className="projects-grid">
                {filteredGroups.map(group => (
                    <ProjectCard
                        key={group.group_id}
                        group={group}
                        onDelete={() => handleDeleteGroup(group.group_id)}
                        onEdit={() => handleEdit(group)}
                    />
                ))}
            </div>

            <Modal
                isOpen={modalOpen}
                onClose={() => setModalOpen(false)}
                project={selectedProject}
                onLanguageSelect={handleLanguageSelect}
            />
        </>
    );
};

export default ProjectGrid;